<template>
  <section class="ministry-activity">
    <EditorJSComponent :text="activity | getArticle" v-if="activity.description" />
    <div class="ministry-activity__files" v-if="documents.length">
      <DocumentItemLoad :data="file" v-for="(file, i) in documents" :key="i" no-height />
    </div>
    <div v-if="childs && childs.length" class="ministry-activity__list">
      <ActivityItem v-for="(activity, i) in childs" :key="i" :data="activity" white />
    </div>
    <div class="ministry-activity__count">
      <span class="ministry-activity__count-title">Количество просмотров:</span>
      <span class="ministry-activity__count-content">{{ count }}</span>
    </div>
  </section>
</template>

<script>
import MINISTRY_ACTIVITY_OPEN_PAGE from "gql/pages/MinistryActivityOpenPage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import DocumentItemLoad from "components/DocumentItemLoad.vue";
import ActivityItem from "components/ActivityItem.vue";
import ADD_VIEW from "gql/mutations/AddView.graphql";

export default {
  name: "MinistryActivityOpenPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: MINISTRY_ACTIVITY_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          type_id: store.state._types.COUNTER_ACTIVITY_TYPE,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("activity/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    childs() {
      return this.activity.child_activities.map((c) => this.getAsLink(c));
    },
    parents() {
      return this.getParents(this.activity, []).reverse();
    },
    activity() {
      return this.$store.state.activity.activities_item;
    },
    count() {
      return this.$store.state.activity.item_views_count;
    },
    documents() {
      return this.activity.files.map((f) => ({
        id: f.id,
        file: f.file,
        title: f.title,
        accepted_at: f.created_at,
      }));
    },
  },
  created() {
    this.$route.meta.links = this.parents;
    this.$router.replace({ query: { temp: undefined } });
  },
  mounted() {
    this.counterUpdate();
  },
  methods: {
    counterUpdate() {
      const variables = {
        id: parseInt(this.$route.params.id),
        type_id: this.$store.state._types.COUNTER_ACTIVITY_TYPE,
      };
      this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: variables,
        })
        .then(({ data }) => {
          if (data && data.AddView) {
            console.log("успешно");
          }
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
        });
    },
    getParents(item, array) {
      let newArray = array;
      if (item.parent) {
        newArray.push(this.getAsLink(item.parent));
        return this.getParents(item.parent, newArray);
      }
      return array;
    },
    getAsLink(item) {
      return {
        title: item.title,
        updated_at: item.updated_at,
        to: {
          name: "ministry-activity-item",
          params: {
            id: item.id,
          },
        },
      };
    },
  },
  metaInfo() {
    const meta = this.$store.state.activity.activities_item;
    return {
      title: meta?.meta_title || meta?.title,
      meta: [
        {
          name: "description",
          content: meta?.meta_description,
        },
        {
          name: "keywords",
          content: meta?.meta_keywords,
        },
      ],
    };
  },
  components: {
    ActivityItem,
    DocumentItemLoad,
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
.ministry-activity {
  display grid
  grid-gap 50px
  margin-bottom 50px
  +below(1024px) {
    grid-gap 30px
    margin-bottom 30px
  }

  &__list {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 30px
    +below(1024px) {
      grid-template-columns repeat(1, 1fr)
    }
    +below(420px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }

  &__files {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px

    +below(1600px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(1280px) {
      grid-template-columns repeat(3,1fr)
    }
    +below(1024px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

  &__count {
    display grid
    grid-gap 10px
    //margin-top 18px

    &-title {
      font-weight 700
      font-size 1.25em
      line-height 28px
      color var(--dark)
    }

    &-content {
      font-weight 500
      font-size 1.25em
      line-height 28px
      color var(--dark)
    }
  }
}
</style>
