<template>
  <a
    class="document-item-load"
    :class="{ 'document-item--no-height': noHeight }"
    :href="$store.state._env.MEDIA_ENDPOINT + path"
    download=""
  >
    <div class="document-item-load__header">
      <IconComponent category="vuesax-bold" name="document-text" />
      <time class="document-item-load__date" v-if="data.published_at">
        {{ data.published_at | humanDateShort }}</time
      >
    </div>
    <h3 class="document-item-load__title">{{ data.title }}</h3>
  </a>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "DocumentItem",
  props: {
    data: Object,
    noHeight: Boolean,
  },
  computed: {
    path() {
      return this.data.file.path;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.document-item-load {
  min-height auto
  width 100%
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 5px;
  display grid
  padding 30px
  grid-gap 20px
  align-content start
  cursor pointer
  +below(1280px) {
    min-height auto
    width 100%
  }

  &--no-height {
    min-height auto
  }

  &__header {
    display grid
    grid-template-columns 40px auto
    grid-gap 10px

    .icon {
      width 30px
      height: 30px;

      svg path {
        fill var(--main)
      }
    }
  }

  &__date {
    font-weight: 400;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark-light);
    +below(1280px) {
      font-weight: 500;
      font-size: 0.875em
      line-height: 24px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1em;
    line-height: 26px;
    margin 0
    color: var(--dark);
    +below(1280px) {
      font-size: 0.875em;
      line-height: 24px;
    }
  }
}
</style>
